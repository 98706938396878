<template>
  <div class="oci-projects-button-container">
    <b-button
      type="submit"
      @click="cancelSigIn"
    >
      Cancel sign-in
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { messageBox } from '@/components/ui/MessageBox'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'

export default {
  name: 'CancelSignedOutButton',
  components: {
    BButton,
    BFormInput,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ operativeSiteId: 'app/getCurrentSiteOperativeId' }),
    ...mapGetters({ site: 'app/getCurrentSite' }),
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
  },
  methods: {
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },
    cancelSigIn() {
      swal.fire({
        title: '',
        html: 'Are you sure you want to cancel sign in?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'I\'m sure',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmIcon: 'primary',
          confirmButton: 'btn btn-primary order-2 ml-1',
          cancelIcon: 'outline-primary',
          cancelButton: 'btn btn-outline-primary order-1 mr-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed === true) {
        checkInOutMethods.CancelSignIn(this.assignmentID)
        this.$router.push('/projects');
          return          
        }
      })
    },

  },
}
</script>
