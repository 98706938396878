<template>
  <div :class="buttonContainerCss">
    <b-button
      type="submit"
      @click="checkInOut"  
      :disabled="!checkInOutButtonEnable"    
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { messageBox } from '@/components/ui/MessageBox'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'

export default {
  name: 'CheckInOutButton',
  components: {
    BButton,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
    accuracy: {
      type: Number,
      required: false,
    },    
  },
  data() {
    return {
      checkInOutButtonEnable: true,
    }
  },
  computed: {
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ operativeSiteId: 'app/getCurrentSiteOperativeId' }),
    ...mapGetters({ site: 'app/getCurrentSite' }),
    buttonContainerCss() {
      console.log(this.checkedInStatus)
      // Only if checked in do we show checked out style
      if (this.checkedInStatus === 'in') return 'oci-check-out-button-container'

      return 'oci-check-in-button-container'
    },
    buttonText() {
      // Only if checked in do we show checked out style

      if (this.isMap) {
        if (this.checkedInStatus === 'in' || this.checkedInStatus === 'out') {
          return 'Sign out'  
        }else{
          return 'Sign in'
        }
      }  

      if (this.checkedInStatus === 'in') return 'Contractor sign-out'
      return 'Contractor sign-in'
    },
  },
  methods: {
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },  
    checkInOut() {
      this.checkInOutButtonEnable = false;
      if (this.isMap) {
        console.log('longitude: ', this.longitude)
        console.log('latitude: ', this.latitude)
        console.log('accuracy: ', this.accuracy)
        if (this.longitude === undefined || this.latitude === undefined || (this.longitude === 0 && this.latitude === 0)) {
          // messageBox.displayStandard('Error', 'Unable to check in/out without map enabled', 'error');
          this.showWarningMessage('Please activate location services on your browser.', 'Location not available')
          this.checkInOutButtonEnable = true;
          return
        }
      }
      // Checked in so checking out now
      if (this.checkedInStatus === 'in') {
        this.checkOut()
        return
      }

      this.checkIn()
    },
    async checkIn() {
      if (this.isMap) {
        this.checkInOutButtonEnable = false;
        await checkInOutMethods.CheckIn(this.operativeSiteId, this.latitude, this.longitude, this.accuracy).then(() => {
          this.checkInOutButtonEnable = true;  
          return
        })
      }
      this.$router.push('/map')
    },
    checkNextAssignment() {
      // loginMethods.GetWebUserTokenCreate()
    },    
    async checkOut() {
      if (this.isMap) {
        this.checkInOutButtonEnable = false;
        checkInOutMethods.CheckOut(this.operativeSiteId, this.latitude, this.longitude, this.accuracy, this.checkNextAssignment).then(() => {
          this.checkInOutButtonEnable = true;  
          return
        })
      }
      this.$router.push('/map')
    },
  },
}
</script>
